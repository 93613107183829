export const environment = {
  production: true,
  apiBaseUrl: 'https://b101t01as-backend01.azurewebsites.net',
  clientId: '62c75ee9-85c7-4090-875b-07f36d7be06b',
  authority: 'https://login.microsoftonline.com/fad277c9-c60a-4da1-b5f3-b3b8b34a82f9',
  redirectUri: 'https://sit.funding-data.education.gov.uk/.auth/login/aad/callback',
  scope: ['https://fundingdataserviceapisit/user_impersonation'],
  sysAdminGroup: 'AZURE-FDS-TE-SYSADMIN-USERS',
  useLocalHttpInterceptor: false,
  localToken: '',
  environment: 'sit',
  clarityProjectId: ''
};
